import moment from 'moment';
import { Moment } from 'moment/moment';
import { sanitizeMinutes_roundUp } from './utils';

export const CLOCK_FORMAT = 'HH:mm';

// This must always be the same as AvailabilityCalculator.MINIMUM_RESERVATION_LENGTH_MINUTES.
export const MINIMUM_RESERVATION_LENGTH_MINUTES = 60;
export const MAXIMUM_RESERVATION_LENGTH_DAYS = 30;

// The format of the arguments is 12:30, moment.format('HH:mm')
export const timeIsInsidePeriod = (
    time: string,
    periodStart: string,
    periodEnd: string
) => {
    let timeHour = parseInt(time.substr(0, 2), 10);
    let timeMinutes = parseInt(time.substr(3, 2), 10);

    let minHour = parseInt(periodStart.substr(0, 2), 10);
    let minMinutes = parseInt(periodStart.substr(3, 2), 10);

    let maxHour = periodEnd ? parseInt(periodEnd.substr(0, 2), 10) : 24;
    let maxMinutes = periodEnd ? parseInt(periodEnd.substr(3, 2), 10) : 30;

    if (minHour <= timeHour && timeHour <= maxHour) {
        if (timeHour === maxHour && timeMinutes > maxMinutes) {
            return false;
        } else if (timeHour === minHour && timeMinutes < minMinutes) {
            return false;
        } else return true;
    } else {
        return false;
    }
};

// newTime must be on the format 'HH:mm'
function parseTimeBasedOnPreviousTime(
    prev: Moment | undefined,
    newTime: string
) {
    const ret = moment(prev).seconds(0);
    ret.hours(parseInt(newTime.substr(0, 2), 10));
    ret.minutes(parseInt(newTime.substr(3, 2), 10));
    return ret;
}

export type UpdateTimeType = 'UPDATE_START_TIME' | 'UPDATE_END_TIME';
export const validateAndUpdateTime = (
    type: UpdateTimeType,
    newTime: 'nå' | string, // string on the format 'HH:mm'
    startTime: Moment | undefined,
    endTime: Moment,
    setStartTime: (time?: Moment) => void,
    setEndTime: (time: Moment) => void,
    setDates?: (start: Moment | undefined, end: Moment) => void,
    mustOverlap?: Moment[]
) => {
    let newStartTime = moment(startTime);
    let newEndTime = moment(endTime);
    if (newTime === 'nå') {
        setStartTime(undefined);
        if (setDates) {
            setDates(undefined, endTime);
        }
        return;
    } else {
        switch (type) {
            case 'UPDATE_START_TIME':
                newStartTime = parseTimeBasedOnPreviousTime(startTime, newTime);
                if (
                    mustOverlap &&
                    mustOverlap[1] &&
                    newStartTime > mustOverlap[1]
                ) {
                    newStartTime = mustOverlap[1];
                }
                setStartTime(newStartTime);
                newEndTime = moment.max(
                    newEndTime,
                    moment(newStartTime).add(
                        MINIMUM_RESERVATION_LENGTH_MINUTES,
                        'minutes'
                    )
                );
                break;
            case 'UPDATE_END_TIME':
                newEndTime = parseTimeBasedOnPreviousTime(endTime, newTime);
                if (
                    mustOverlap &&
                    mustOverlap[0] &&
                    newEndTime < mustOverlap[0]
                ) {
                    newEndTime = mustOverlap[0];
                }
                newStartTime = moment.min(
                    newStartTime,
                    moment(newEndTime).subtract(
                        MINIMUM_RESERVATION_LENGTH_MINUTES,
                        'minutes'
                    )
                );
                break;
        }
    }
    setStartTime(newStartTime);
    setEndTime(newEndTime);
    if (setDates) setDates(newStartTime, newEndTime);
};

/**
 * Complex method to take care of every possible invalid edge case when both start time and end time may be changed by
 */
export type UpdateDateType = 'UPDATE_START_DATE' | 'UPDATE_END_DATE';
export const validateAndUpdateDateAndTimeBasedOnNewDate = (
    type: UpdateDateType,
    newDate: Moment | null,
    currentStartTime: Moment | undefined,
    currentEndTime: Moment,
    setStartTime: (start?: Moment) => void,
    setEndTime: (end: Moment) => void,
    setDates: (start: Moment | undefined, end: Moment) => void,
    insidePeriod?: {
        start?: Moment;
        end?: Moment;
    }
) => {
    let safeCurrentStartTime = moment(currentStartTime);
    let safeCurrentEndTime = moment(currentEndTime);

    let returnStart;
    let returnEnd;
    if (
        type === 'UPDATE_START_DATE' &&
        !currentStartTime &&
        moment(newDate).isSame(moment(), 'day')
    ) {
        setStartTime(undefined);
    } else {
        let currentTime =
            type === 'UPDATE_START_DATE'
                ? safeCurrentStartTime
                : safeCurrentEndTime;
        // Create newTime based on the new date and the current time, because the times in the new date-object should not be used
        let newTime = moment(newDate)
            .hour(currentTime.hour())
            .minute(currentTime.minute());

        if (type === 'UPDATE_START_DATE') {
            newTime = sanitizeMinutes_roundUp(newTime);
        }

        // if newTime is today make sure that that newTime is after now, increment to nearest 30 min if necessary
        if (isToday(newTime)) {
            let now = moment();
            // if we are updating the endTime we need to compare to 60 mins from now
            if (type === 'UPDATE_END_DATE')
                now.add(MINIMUM_RESERVATION_LENGTH_MINUTES, 'm');

            if (newTime.hour() <= now.hour()) {
                if (now.minute() >= 30) {
                    newTime.hour(now.hour()).add(1, 'h');
                    newTime.minute(0);
                } else {
                    newTime.hour(now.hour());
                    newTime.minute(30);
                }
            }
        }

        const checkInsidePeriod = () => {
            // Make sure that the new time is not outside the period if one is specified
            if (!insidePeriod) return;
            let periodStart = insidePeriod.start;
            let periodEnd = insidePeriod.end;
            if (periodStart && newTime.isBefore(periodStart))
                newTime = moment.max(
                    periodStart,
                    safeCurrentStartTime.add(
                        MINIMUM_RESERVATION_LENGTH_MINUTES,
                        'minute'
                    )
                );
            if (periodEnd && newTime.isAfter(periodEnd))
                newTime = moment.min(
                    periodEnd,
                    safeCurrentEndTime.subtract(
                        MINIMUM_RESERVATION_LENGTH_MINUTES,
                        'minute'
                    )
                );
        };

        if (type === 'UPDATE_START_DATE') {
            checkInsidePeriod();
            returnStart = newTime;
            setStartTime(newTime);

            // If the change in start time has made the current end time invalid we need to change the current end time
            if (
                safeCurrentEndTime.isBefore(
                    moment(newTime).add(MINIMUM_RESERVATION_LENGTH_MINUTES, 'm')
                )
            ) {
                let newEndTime = moment(newTime).add(
                    MINIMUM_RESERVATION_LENGTH_MINUTES,
                    'm'
                );
                setEndTime(newEndTime);
                returnEnd = newEndTime;
            }
        } else if (type === 'UPDATE_END_DATE') {
            // If new time is not after the current start time, change the new time so that it is after the current start time
            if (
                moment(safeCurrentStartTime)
                    .add(MINIMUM_RESERVATION_LENGTH_MINUTES, 'm')
                    .isAfter(newTime)
            ) {
                newTime = moment(safeCurrentStartTime).add(
                    MINIMUM_RESERVATION_LENGTH_MINUTES,
                    'm'
                );
            }

            checkInsidePeriod();
            returnEnd = newTime;
            setEndTime(newTime);
            // if (setDates) setDates(currentStartTime, newTime);
        }

        setDates(
            returnStart ? returnStart : currentStartTime,
            returnEnd ? returnEnd : currentEndTime
        );
    }
};

export const isToday = (time: Moment) => time.isSame(moment(), 'day');

export const getInitializationEndTime = () =>
    sanitizeMinutes_roundUp(moment().second(0).add(3, 'hour'));

export const dateValidationRegex = /^(?:(?:31([/\-.])(?:0?[13578]|1[02]))\1|(?:(?:29|30)([/\-.])(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29([/\-.])0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])([/\-.])(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
