import { createAction } from 'redux-actions';

const clearDataState = createAction('CLEAR_DATA_STATE');
const clearState = createAction('CLEAR_STATE');

const setShouldAuthenticate = createAction('SET_SHOULD_AUTHENTICATE');

const setPerformAfterLogin = createAction('SET_ACTION_TO_PERFORM_AFTER_LOGIN');
const clearPerformAfterLogin = createAction(
    'CLEAR_ACTION_TO_PERFORM_AFTER_LOGIN'
);

const authenticationPending = createAction('AUTHENTICATION_PENDING');
const authenticationCompleted = createAction('AUTHENTICATION_COMPLETED');
const authenticationError = createAction('AUTHENTICATION_ERROR');

const vippsJoinPending = createAction('VIPPS_JOIN_PENDING');
const vippsJoinCompleted = createAction('VIPPS_JOIN_COMPLETED');
const vippsJoinError = createAction('VIPPS_JOIN_ERROR');
const resetVippsJoinError = createAction('RESET_VIPPS_JOIN_ERROR');

const linkVippsUserPending = createAction('LINK_VIPPS_USER_ERROR_PENDING');
const linkVippsUserCompleted = createAction('LINK_VIPPS_USER_ERROR_COMPLETED');
const linkVippsUserError = createAction('LINK_VIPPS_USER_ERROR_ERROR');

const joinPending = createAction('JOIN_PENDING');
const joinCompleted = createAction('JOIN_COMPLETED');
const joinError = createAction('JOIN_ERROR');
const resetJoinResult = createAction('RESET_JOIN_RESULT');

const gcreAuthPending = createAction('GCRE_AUTH_PENDING');
const gcreAuthCompleted = createAction('GCRE_AUTH_COMPLETED');
const gcreAuthError = createAction('GCRE_AUTH_ERROR');

const sendPasswordResetEmailPending = createAction(
    'SEND_PASSWORD_RESET_EMAIL_PENDING'
);
const sendPasswordResetEmailCompleted = createAction(
    'SEND_PASSWORD_RESET_EMAIL_COMPLETED'
);
const sendPasswordResetEmailError = createAction(
    'SEND_PASSWORD_RESET_EMAIL_ERROR'
);
const resetSendPasswordResetEmailResult = createAction(
    'RESET_SEND_PASSWORD_RESET_EMAIL_RESULT'
);

const resetPasswordPending = createAction('RESET_PASSWORD_PENDING');
const resetPasswordCompleted = createAction('RESET_PASSWORD_COMPLETED');
const resetPasswordError = createAction('RESET_PASSWORD_ERROR');
const resetResetPasswordResult = createAction('RESET_RESET_PASSWORD_RESULT');

const setUserName = createAction('SET_USER_NAME');

const clearAuthentication = createAction('CLEAR_AUTHENTICATION');

const setAdmin = createAction('SET_ADMIN');

const apiResponseError = createAction('API_RESPONSE_ERROR');

const setShowIncorrectLoginFeedback = createAction(
    'SET_SHOW_INCORRECT_LOGIN_FEEDBACK'
);

const setSelectedMembership = createAction('SET_SELECTED_MEMBERSHIP');

const setSelectedOrganization = createAction('SET_SELECTED_ORGANIZATION');

const setTheme = createAction('SET_THEME');

const setPage = createAction('SET_PAGE');

const fetchingNewPagePending = createAction('FETCHING_NEW_PAGE_PENDING');
const fetchingNewPageCompleted = createAction('FETCHING_NEW_PAGE_COMPLETED');
const fetchingNewPageError = createAction('FETCHING_NEW_PAGE_ERROR');
const resetPagination = createAction('RESET_PAGINATION');

const toggleShowHamburgerMenu = createAction(`TOGGLE_SHOW_HAMBURGER_MENU`);

const showHamburgerMenu = createAction(`SHOW_HAMBURGER_MENU`);

const initApp = createAction('INITIALIZING_APP');
const initApp__positionCompleted = createAction(
    'INITIALIZING_APP__POSITION_COMPLETED'
);
const initApp__filtersCompleted = createAction(
    'INITIALIZING_APP__FILTERS_COMPLETED'
);
const initApp__locationsCompleted = createAction(
    'INITIALIZING_APP__LOCATIONS_COMPLETED'
);
const initAppError = createAction('INITIALIZING_APP_ERROR');

const apiCall = createAction('API_CALL');

const whoamiResponse = createAction('WHOAMI_RESPONSE');

const fetchOrganizationsPending = createAction('FETCH_ORGANIZATIONS_PENDING');
const fetchOrganizationsCompleted = createAction(
    'FETCH_ORGANIZATIONS_COMPLETED'
);
const fetchOrganizationsError = createAction('FETCH_ORGANIZATIONS_ERROR');

const swapCarPending = createAction('SWAP_CAR_PENDING');
const swapCarCompleted = createAction('SWAP_CAR_COMPLETED');
const swapCarError = createAction('SWAP_CAR_ERROR');
const resetSwapCarResult = createAction('RESET_SWAP_CAR_RESULT');

const setSwapCarFilters = createAction('SET_SWAP_CAR_FILTERS');
const setSwapCarFiltersAreActive = createAction(
    'SET_SWAP_CAR_FILTERS_ARE_ACTIVE'
);

const setSwapCarReservation = createAction('SET_SWAP_CAR_RESERVATION');
const setSwapCarPosition = createAction('SET_SWAP_CAR_POSITION');
const setSwapCarTimes = createAction('SET_SWAP_CAR_TIMES');

const actions = {
    clearDataState,
    clearState,
    setShouldAuthenticate,
    setSelectedOrganization,
    setPerformAfterLogin,
    clearPerformAfterLogin,
    authenticationPending: authenticationPending,
    authenticationCompleted: authenticationCompleted,
    setUserName,
    clearAuthentication: clearAuthentication,
    setAdmin,
    authenticationError: authenticationError,
    vippsJoinPending,
    vippsJoinCompleted,
    vippsJoinError,
    resetVippsJoinError,
    linkVippsUserPending,
    linkVippsUserCompleted,
    linkVippsUserError,
    joinPending,
    joinCompleted,
    joinError,
    gcreAuthPending,
    gcreAuthCompleted,
    gcreAuthError,
    resetJoinResult,
    sendPasswordResetEmailPending,
    sendPasswordResetEmailCompleted,
    sendPasswordResetEmailError,
    resetSendPasswordResetEmailResult,
    resetPasswordPending,
    resetPasswordCompleted,
    resetPasswordError,
    resetResetPasswordResult,
    setShowIncorrectLoginFeedback,
    apiResponseError,
    setSelectedMembership,
    setTheme,
    toggleShowHamburgerMenu,
    showHamburgerMenu,
    setPage,
    fetchingNewPagePending,
    fetchingNewPageCompleted,
    fetchingNewPageError,
    resetPagination,
    initApp,
    initApp__positionCompleted,
    initApp__filtersCompleted,
    initApp__locationsCompleted,
    initAppError,
    apiCall,
    whoamiResponse,
    fetchOrganizationsPending,
    fetchOrganizationsCompleted,
    fetchOrganizationsError,
    swapCarPending,
    swapCarCompleted,
    swapCarError,
    setSwapCarFilters,
    setSwapCarFiltersAreActive,
    setSwapCarReservation,
    setSwapCarPosition,
    setSwapCarTimes,
    resetSwapCarResult,
};

export default actions;
