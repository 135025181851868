import React, { Fragment } from 'react';
import Modal from '../Modal';
import Button from '../Buttons/Button';
import { selectTheme } from '../../duck/selectors';
import { useAppSelector } from '../../../state/hooks';
import { CLOCK_FORMAT } from '../../../utilities/timeUtils';

export const ErrorModal = (props) => {
    const theme = useAppSelector(selectTheme);
    return (
        <Modal closeModal={props.onClose}>
            <div className="errorModal">
                <h2 className="errorModal__header" style={theme.text.h4}>
                    {props.heading}
                </h2>
                <div
                    className="errorModal__body"
                    role="alert"
                    style={theme.text.body2}
                >
                    {props.errorMessage}
                </div>

                {props.type === 'PARTIAL_AVAILABILITY' ? (
                    <Fragment>
                        <div
                            className="errorModal__optionalInfo"
                            style={theme.text.body1}
                        >
                            <div>
                                Fra {props.optionalInfo.start.format('dddd')}{' '}
                                {props.optionalInfo.start.format('D.MM.YY')}, kl{' '}
                                {props.optionalInfo.start.format(CLOCK_FORMAT)}
                            </div>
                            <div>
                                Til {props.optionalInfo.end.format('dddd')}{' '}
                                {props.optionalInfo.end.format('D.MM.YY')}, kl{' '}
                                {props.optionalInfo.end.format(CLOCK_FORMAT)}
                            </div>
                        </div>

                        <div className="errorModal__buttons">
                            <Button
                                buttonType={'optionButton'}
                                label={'Ja'}
                                onClick={() => {
                                    props.props.endReservation(
                                        props.props.reservation.id,
                                        props.state.damage
                                    );
                                }}
                                theme={theme}
                                styleOverride={{
                                    marginRight: '10px',
                                }}
                            />
                            <Button
                                buttonType={'optionButton'}
                                label={'Nei'}
                                onClick={props.onClose}
                                theme={theme}
                                styleOverride={{
                                    backgroundColor: theme.colors.orange,
                                    marginLeft: '10px',
                                }}
                            />
                        </div>
                    </Fragment>
                ) : (
                    ''
                )}
            </div>
        </Modal>
    );
};
