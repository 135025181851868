import React, { useRef } from 'react';
import useEffectOnce from '../../utilities/UseEffectOnce';
import { CloseModalButton } from './Buttons/CloseModalButton';

const Modal = ({ children, closeModal, close = true }) => {
    const ref = useRef();

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            closeModal();
        }
    };

    useEffectOnce(() => {
        ref.current.showModal();

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    return (
        <dialog ref={ref}>
            {close && (
                <CloseModalButton
                    className={'dialog-close'}
                    onClick={() => closeModal()}
                />
            )}
            {children}
        </dialog>
    );
};

export default Modal;
