import { selectSelectedMembership } from '../../../duck/selectors';

export const selectFetchingReservations = (state) =>
    state.reservationsReducer.data.fetchingReservations;

export const selectFetchingReservation = (state) =>
    state.reservationsReducer.data.fetchingReservation;

export const selectFetchReservationResult = (state) =>
    state.reservationsReducer.data.fetchReservationResult;

export const selectCancelReservationPending = (state) =>
    state.reservationsReducer.data.cancelReservationPending;

export const selectCancelReservationResult = (state) =>
    state.reservationsReducer.data.cancelReservationResult;

export const selectUpdatingReservationUserNote = (state) =>
    state.reservationsReducer.data.updatingReservationUserNote;

export const selectUpdateReservationUserNoteResult = (state) =>
    state.reservationsReducer.data.updateReservationUserNoteResult;

export const selectExtendReservationPending = (state) =>
    state.reservationsReducer.data.extendReservationPending;

export const selectDialToOpenPending = (state) =>
    state.reservationsReducer.data.dialToOpenPending;

export const selectDialToOpenError = (state) =>
    state.reservationsReducer.data.dialToOpenError;

export const selectDialToOpenCompleted = (state) => {
    return state.reservationsReducer.data.dialToOpenCompleted;
};

export const selectReservationExtendability = (state) =>
    state.reservationsReducer.data.reservationExtendability;

export const selectExtendReservationError = (state) =>
    state.reservationsReducer.data.extendReservationError;

export const selectExtensionHighLight = (state) =>
    state.reservationsReducer.data.extensionHighLight;

export const selectShowCancelReservationSuccessModal = (state) =>
    state.reservationsReducer.data.showCancelReservationSuccessModal;

export const selectCancelReservationSuccessModalText = (state) =>
    state.reservationsReducer.data.cancelReservationSuccessModalText;

export const selectOngoingAndFutureReservations = (state) => {
    const res = state.reservationsReducer.data.activeReservationList;
    if (!res) return [];
    return res.filter((reservation) =>
        ['ONGOING', 'FUTURE'].includes(reservation.state)
    );
};

export const selectShouldRedirectToReservations = (state) =>
    state.reservationsReducer.data.shouldRedirectToReservations;

export const selectActiveReservations = (state) =>
    state.reservationsReducer.data.activeReservationList || [];

export const selectMembershipActiveReservations = (state) => {
    let reservations = state.reservationsReducer.data.activeReservationList;
    if (!reservations) return;
    let selectedMembership = selectSelectedMembership(state);

    if (selectedMembership)
        reservations = reservations.filter(
            (reservation) => reservation.membershipId === selectedMembership.id
        );
    return reservations;
};

export const selectFetchingMembershipActiveReservations = (state) =>
    state.reservationsReducer.data.fetchingActiveReservations;

export const selectMembershipHistoricReservations = (state) => {
    let reservations = state.reservationsReducer.data.historicReservationList;
    if (!reservations) return;
    let selectedMembership = selectSelectedMembership(state);

    if (selectedMembership)
        reservations = reservations.filter(
            (reservation) => reservation.membershipId === selectedMembership.id
        );
    return reservations;
};

export const selectMembershipHistoricReservationCount = (state) =>
    state.reservationsReducer.data.historicReservationCount;

export const selectFetchingMembershipHistoricReservations = (state) =>
    state.reservationsReducer.data.fetchingHistoricReservations;

export const selectReservation = (state) => {
    return state.reservationsReducer.data.reservation;
};

export const selectReservationDrivers = (state) => {
    return state.reservationsReducer.data.reservationDrivers;
};
export const selectFetchingReservationDrivers = (state) => {
    return state.reservationsReducer.data.fetchingReservationDrivers;
};
export const selectFetchReservationDriversResult = (state) => {
    return state.reservationsReducer.data.fetchReservationDriversResult;
};

export const selectUpdatingReservationDrivers = (state) => {
    return state.reservationsReducer.data.updatingReservationDrivers;
};
export const selectUpdateReservationDriversResult = (state) => {
    return state.reservationsReducer.data.updateReservationDriversResult;
};

export const selectUnseenReservations = (state) => {
    return state.reservationsReducer.data.unseenReservations;
};

export const selectCarDistances = (state) => {
    return state.reservationsReducer.data.carDistances;
};

export const selectFetchingReservationsByCarId = (state) =>
    state.reservationsReducer.data.fetchingReservationsByCarID;

export const selectReservationsByCarId = (state) => {
    //TODO improvement, take in carId as param
    return state.reservationsReducer.data.reservationsByCarId;
};

export const selectFetchingFindCarInfo = (state) =>
    state.reservationsReducer.data.fetchingFindCarInfo;

export const selectFetchingFindCarInfoResult = (state) =>
    state.reservationsReducer.data.fetchingFindCarInfoResult;

export const selectPutReservationPending = (state) =>
    state.reservationsReducer.data.putReservationPending;

export const selectPutReservationResult = (state) =>
    state.reservationsReducer.data.putReservationResult;

export const selectPutReservationErrorType = (state) =>
    state.reservationsReducer.data.putReservationErrorType;

export const selectEntityMessages = (state) =>
    state.reservationsReducer.data.entityMessages;

export const selectPreLoadingActiveReservations = (state) =>
    state.reservationsReducer.data.preLoadingActiveReservations;

export const selectPreLoadedReservations = (state) =>
    state.reservationsReducer.data.preLoadedReservations;

export const selectMembershipPreLoadedReservations = (state) => {
    let pr = state.reservationsReducer.data.preLoadedReservations;
    let sm = selectSelectedMembership(state);
    if (!pr || !sm) return [];
    return pr.filter((r) => r.membershipId === sm.id);
};

// get resrevations by car id
