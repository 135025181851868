import { createSlice } from '@reduxjs/toolkit';
import {
    FcmToken,
    NotificationSettings,
    PublicId,
} from '../../../../types/common';
import { ApiCallResult } from '../../../../types/application';
import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../utilities/constants';

export type NotificationSettingsState = {
    notificationSettings?: NotificationSettings;
    fetchingNotificationSettings: boolean;
    fetchNotificationSettingsResult: ApiCallResult;

    togglingFcmToken: boolean;
    toggleFcmTokenResult: ApiCallResult;
};
const defaultNotificationSettingState: NotificationSettingsState = {
    fetchingNotificationSettings: false,
    fetchNotificationSettingsResult: undefined,

    togglingFcmToken: false,
    toggleFcmTokenResult: undefined,
};

const notificationSettingSlice = createSlice({
    name: 'notificationSettings',
    initialState: defaultNotificationSettingState,
    reducers: {
        fetchNotificationSettingsPending(state: NotificationSettingsState) {
            state.fetchingNotificationSettings = true;
            state.fetchNotificationSettingsResult = undefined;
        },
        fetchNotificationSettingsCompleted(
            state: NotificationSettingsState,
            action: { payload: NotificationSettings }
        ) {
            state.notificationSettings = action.payload;
            state.fetchingNotificationSettings = false;
            state.fetchNotificationSettingsResult = apiCallSuccess;
        },
        fetchNotificationSettingsError(state: NotificationSettingsState) {
            state.fetchingNotificationSettings = false;
            state.fetchNotificationSettingsResult = apiCallError;
        },

        toggleFcmTokenPending(state: NotificationSettingsState) {
            state.togglingFcmToken = true;
            state.toggleFcmTokenResult = undefined;
        },
        toggleFcmTokenCompleted(
            state: NotificationSettingsState,
            action: { payload: FcmToken }
        ) {
            state.togglingFcmToken = false;
            state.toggleFcmTokenResult = apiCallSuccess;
            if (state.notificationSettings) {
                state.notificationSettings = {
                    ...state.notificationSettings,
                    tokens: (
                        state.notificationSettings?.tokens ?? []
                    ).map((tok) =>
                        tok.id === action.payload.id ? action.payload : tok
                    ),
                };
            }
        },
        toggleFcmTokenError(state: NotificationSettingsState) {
            state.togglingFcmToken = false;
            state.toggleFcmTokenResult = apiCallError;
        },

        deleteFcmTokenCompleted(
            state: NotificationSettingsState,
            action: { payload: PublicId }
        ) {
            if (state.notificationSettings) {
                state.notificationSettings = {
                    ...state.notificationSettings,
                    tokens: (state.notificationSettings?.tokens ?? []).filter(
                        (tok) => tok.id !== action.payload
                    ),
                };
            }
        },

        updateFcmTokenCompleted(
            state: NotificationSettingsState,
            action: { payload: FcmToken }
        ) {
            if (state.notificationSettings?.tokens) {
                const tokens: FcmToken[] = [];
                let newToken = true;
                state.notificationSettings.tokens.forEach((tok) => {
                    if (tok.id === action.payload.id) {
                        tokens.push(action.payload);
                        newToken = false;
                    } else tokens.push(tok);
                });
                if (newToken) tokens.push(action.payload);
                state.notificationSettings = {
                    ...state.notificationSettings,
                    tokens,
                };
            }
        },

        updateNotificationPermissionCompleted(
            state: NotificationSettingsState,
            action: { payload: boolean }
        ) {
            if (state.notificationSettings) {
                state.notificationSettings = {
                    ...state.notificationSettings,
                    enabled: action.payload,
                };
            }
        },
    },
});

export const {
    fetchNotificationSettingsPending,
    fetchNotificationSettingsCompleted,
    fetchNotificationSettingsError,

    toggleFcmTokenPending,
    toggleFcmTokenCompleted,
    toggleFcmTokenError,

    deleteFcmTokenCompleted,

    updateFcmTokenCompleted,

    updateNotificationPermissionCompleted,
} = notificationSettingSlice.actions;
export const notificationSettingsReducer = notificationSettingSlice.reducer;
export default notificationSettingsReducer;
