import classnames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { Theme } from '../../../utilities/types';
import { connect } from 'react-redux';
import { selectTheme } from '../../duck/selectors';
import { State } from '../../../state/store';

export type ButtonTypes =
    | 'primary'
    | 'primary__orange'
    | 'primary__disabled'
    | 'optionButton'
    | 'secondaryButton'
    | 'secondaryButton__disabled'
    | 'basicHoverButton'
    | 'textOnly'
    | 'danger';

type Props = {
    buttonType: ButtonTypes;
    onClick?: () => void;
    label?: any;
    theme: Theme;
    styleOverride?: React.CSSProperties;
    link?: any;
    target?: string;
    autoFocus?: boolean;
    bgColor?: string;
    hoverColor?: string;
    testId?: string;
    children?: ReactNode | string;
    disabled?: boolean;
    title?: string;
    className?: string;
};

export const ButtonComponent = (props: Props) => {
    const [hover, setHover] = useState(false);

    const {
        buttonType,
        onClick,
        label,
        theme,
        styleOverride,
        link,
        target,
        autoFocus,
        bgColor,
        hoverColor,
        testId,
        children,
        title,
        disabled,
    } = { ...props };

    let style: React.CSSProperties =
        theme && theme.text
            ? {
                  ...theme.text?.buttonsPri1,
              }
            : {};

    let primaryStyle = () => ({
        ...theme.text?.buttonsPri1,
        backgroundColor: hover
            ? theme.colors?.button_primary__dark
            : theme.colors?.button_primary,
    });

    let danger = () => ({
        ...theme.text?.buttonsPri1,
        backgroundColor: hover
            ? theme.colors?.redButton
            : theme.colors?.redButton__hover,
    });
    let primaryStyle__orange = () => ({
        ...theme.text?.buttonsPri1,
        borderColor: hover
            ? theme.colors?.partially_available_text
            : theme.colors?.partially_available,
        color: theme.colors?.partially_available_text,
    });

    let primaryStyle__disabled = () => ({
        ...theme.text?.buttonsPri1,
        backgroundColor: theme.colors?.disabled,
        cursor: 'default',
    });

    let secondaryStyle = () => ({
        ...theme.text?.buttonsPri1,
        backgroundColor: hover
            ? 'rgba(94, 127, 2, 0.1)'
            : theme.colors?.background,
        color: theme.colors?.primary,
        borderColor: theme.colors?.primary,
    });

    let secondaryStyle_disabled = () => ({
        ...theme.text?.buttonsPri1,
        backgroundColor: theme.colors?.grey__mediumLight,
        color: theme.colors?.background,
        border: 'none',
        // borderColor:  theme.colors?.disabled,
        cursor: 'default',
    });

    let className = '';
    switch (buttonType) {
        case 'primary':
            className = 'primaryButton u-shadowTab';
            style = primaryStyle();
            break;
        case 'primary__orange':
            className = 'secondaryButton u-shadowTab';
            style = primaryStyle__orange();
            break;
        case 'primary__disabled':
            className = 'primaryButton';
            style = primaryStyle__disabled();
            break;
        case 'optionButton':
            className = 'optionButton';
            break;
        case 'secondaryButton':
            className = 'secondaryButton u-shadowTab ';
            style = secondaryStyle();
            break;
        case 'secondaryButton__disabled':
            className = 'secondaryButton u-shadowTab';
            style = secondaryStyle_disabled();
            break;
        case 'danger':
            className = 'primaryButton redButton';
            style = danger();
            break;
        case 'basicHoverButton':
            style = {};
            break;
        case 'textOnly':
            className = 'textOnly';
            style = {
                ...theme.text?.link,
                color: hover
                    ? theme.colors?.links_and_clickables__hover
                    : theme.colors?.links_and_clickables,
                background: 'none',
                textDecoration: 'underline',
            };
            break;
        default:
            break;
    }

    if (props.className) className = classnames(className, props.className);
    if (styleOverride) style = { ...style, ...styleOverride };
    if (hoverColor && hover) {
        style.backgroundColor = hoverColor;
    }
    if (bgColor && !hover) {
        style.backgroundColor = bgColor;
    }

    return link ? (
        <Link
            data-test={testId}
            data-testid={testId}
            to={link}
            target={target}
            style={{ ...style, textDecoration: 'none' }}
            className={classnames('aButton', className)}
            onClick={onClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            title={title}
        >
            {label}
        </Link>
    ) : (
        <button
            data-test={testId}
            data-testid={testId}
            className={className}
            onClick={onClick}
            style={style}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            autoFocus={autoFocus}
            title={title}
            disabled={disabled}
        >
            {label || ''}
            {children || ''}
        </button>
    );
};
export const Button = connect((state: State) => ({
    theme: selectTheme(state),
}))(ButtonComponent);
export default Button;
