import React, { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { NewItemNotification } from '../NewItemNotification/NewItemNotification';
import { ReactComponent as ExternalIcon } from '../../../resources/external-link.svg';
import { Theme } from '../../../utilities/types';
import { LocationDescriptorObject } from 'history';

type Props = {
    link?: string | LocationDescriptorObject;
    label: string;
    theme: Theme;
    exact?: boolean;
    onClick?: () => void;
    newItems?: number;
    styleOverride?: Object;
    activeStyleOverride?: Object;
    isAdminButton?: boolean;
    type?: string;
    testId?: string;
    newTab?: boolean;
    external?: boolean;
    disabled?: boolean;
    children?: ReactNode | string;
    condition?: boolean;
};

export const NavButton = (props: Props) => {
    const [hover, setHover] = useState(false);
    const {
        label,
        exact,
        theme,
        styleOverride,
        type,
        external,
        testId,
        disabled,
        newItems,
        newTab,
        onClick,
        children,
        condition,
    } = { ...props };

    if (condition !== undefined && !condition) {
        return <></>;
    }

    let linkIsActive = false;

    let linkPath = props.link
        ? typeof props.link === 'string'
            ? props.link
            : props.link.pathname
        : undefined;

    if (linkPath) {
        if (exact) {
            linkIsActive = window.location.pathname === linkPath;
        } else if (
            window.location.pathname.substring(0, linkPath.length) === linkPath
        ) {
            linkIsActive = true;
        }
    }

    // Hide active indicator on reservations link when on new reservation page
    if (
        linkPath === '/admin/reservation-management/reservations' &&
        window.location.pathname ===
            '/admin/reservation-management/reservations/new'
    ) {
        linkIsActive = false;
    }

    let style = {
        ...theme.text?.menuText,
        color: linkIsActive
            ? 'white'
            : theme.colors?.hover_on_white_background_color,
        ...styleOverride,
    };

    if (hover) style.color = '#e0e0e8';

    let newItemNotification =
        newItems && newItems > 0 ? (
            <NewItemNotification
                number={newItems}
                theme={theme}
                styleOverride={{
                    marginLeft: '8px',
                }}
            />
        ) : (
            <div />
        );

    if (disabled) {
        return (
            <div
                className={`navButton navButton--disabled ${
                    type === 'subItem'
                        ? 'navButton--subItem'
                        : type === 'whiteItem'
                        ? 'navButton--whiteItem'
                        : external
                        ? 'navButton--external'
                        : ''
                }`}
            >
                <div className={'navButton__navLabel'}>{label}</div>
                {external && (
                    <ExternalIcon className="navButton__externalIcon" />
                )}
            </div>
        );
    }

    const render = () =>
        linkPath && props.link ? (
            type === 'whiteItem' ? (
                <NavLink
                    className={'navButton navButton--whiteItem'}
                    style={style}
                    to={props.link}
                    target={newTab ? '_blank' : undefined}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    exact={exact}
                    onClick={onClick}
                    data-test={testId}
                >
                    <div>{label}</div>
                </NavLink>
            ) : external ? (
                <a
                    className={'navButton navButton--external'}
                    style={style}
                    href={linkPath}
                    target={newTab ? '_blank' : undefined}
                    rel="noreferrer"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={onClick}
                    data-test={testId}
                >
                    <div className={'navButton__navLabel'}>{label}</div>
                    <ExternalIcon />
                </a>
            ) : (
                <NavLink
                    className={'navButton'}
                    activeClassName={'navButton--active'}
                    style={style}
                    to={props.link}
                    target={newTab ? '_blank' : undefined}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    exact={exact}
                    onClick={onClick}
                    data-test={testId}
                >
                    <div className={'navButton__navLabel'}>{label}</div>
                    {newItemNotification}
                </NavLink>
            )
        ) : onClick ? (
            <button
                className={'navButton'}
                style={style}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={onClick}
                data-test={testId}
            >
                <div className={'navButton__navLabel'}>
                    {label}
                    {children}
                </div>
                {newItemNotification}
            </button>
        ) : (
            <div
                className={'navButton'}
                style={style}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                data-test={testId}
            >
                <div className={'navButton__navLabel'}>{label}</div>
                {newItemNotification}
            </div>
        );

    return render();
};

export default NavButton;
