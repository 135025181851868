import { geoJsonToLatLng } from '../../../../utilities/utils';

export const selectSearchParams = (state) => state.searchReducer.search;
export const selectFetchingCars = (state) =>
    state.searchReducer.searchData.fetchingCars;

export const selectCheckingConcurrentReservationLimit = (state) =>
    state.searchReducer.searchData.checkingConcurrentReservationLimit;
export const selectCheckConcurrentReservationLimitResult = (state) =>
    state.searchReducer.searchData.checkConcurrentReservationLimitResult;
export const selectConcurrentReservationLimitResponse = (state) =>
    state.searchReducer.searchData.concurrentReservationLimitResponse;

export const selectMessages = (state) =>
    state.searchReducer.searchData.messages;

export const selectFetchingSwappableCars = (state) =>
    state.searchReducer.searchData.fetchingSwappableCars;
export const selectFetchSwappableCarsResult = (state) =>
    state.searchReducer.searchData.fetchSwappableCarsResult;

export const selectSwappableCars = (state) =>
    prepareCarData(state.searchReducer.searchData.swappableCars, state);

export const selectCars = (state) =>
    prepareCarData(state.searchReducer.searchData.cars, state);

export const prepareCarData = (cars, state) => {
    let locationDistances = selectLocationDistances(state);

    //TODO avoid calling selectCars sooo many times

    return cars.map((car) => {
        let selectedPositionGeoJson = geoJsonToLatLng(
            selectSelectedPosition(state).geojson
        );
        let carsLocationObjectInState = locationDistances.find(
            (location) => location.id === car.location.id
        );

        let currentDistanceOfCarsLocation;
        let selectedPositionId =
            selectedPositionGeoJson.lat + '_' + selectedPositionGeoJson.lng;

        if (
            !!carsLocationObjectInState &&
            !!carsLocationObjectInState.distances &&
            !!carsLocationObjectInState.distances[selectedPositionId]
        ) {
            currentDistanceOfCarsLocation = carsLocationObjectInState.distances[
                selectedPositionId
            ].walkingDistance
                ? carsLocationObjectInState.distances[selectedPositionId]
                      .walkingDistance
                : carsLocationObjectInState.distances[selectedPositionId]
                      .airDistance;
        }

        // TODO: could send both walking- and airdistance to component if we need to show that distance is airdistance
        return {
            ...car,
            currentDistance: currentDistanceOfCarsLocation,
        };
    });
};

export const selectShowingSwapCarOptions = (state) =>
    state.searchReducer.searchData.showingSwapCarOptions;
export const selectLocationDistances = (state) => {
    return state.searchReducer.searchData.locationDistances.map((location) => {
        return { ...location };
    });
};
export const selectGpsPositionDisabled = (state) =>
    state.searchReducer.search.gpsPositionDisabled;
export const selectGettingGpsPosition = (state) =>
    state.searchReducer.search.gettingGpsPosition;

export const selectShouldShowCarFilterView = (state) =>
    state.searchReducer.ui.showCarFilterView;

export const selectLocationSearchString = (state) =>
    state.searchReducer.searchData.locationSearchString;
export const selectPreviouslySelectedLocations = (state) =>
    state.searchReducer.searchData.previouslySelectedLocations;
export const selectFetchingExternalLocations = (state) =>
    state.searchReducer.searchData.fetchingExternalLocations;

export const selectFetchingLocationDistances = (state) =>
    state.searchReducer.searchData.fetchingLocationDistances;

export const selectExternalLocations = (state) => {
    return state.searchReducer.searchData.externalLocations.map((location) => {
        return { ...location };
    });
};

export const selectStartTime = (state) => state.searchReducer.search.startTime;
export const selectEndTime = (state) => state.searchReducer.search.endTime;

export const selectSelectedPosition = (state) =>
    state.searchReducer.search.selectedPosition;

export const selectFilters = (state) => state.searchReducer.search.filters;

export const selectFiltersAreActive = (state) =>
    state.searchReducer.search.filtersAreActive;

export const selectHideUnavailableCars = (state) =>
    state.searchReducer.search.hideUnavailableCars;

export const selectPriceOfCurrentCar = (state) =>
    state.searchReducer.searchData.priceOfCurrentCar;

export const selectFetchingPriceOfCurrentCar = (state) =>
    state.searchReducer.searchData.fetchingPriceOfCurrentCar;

export const selectSelectedCar = (state) => {
    let id = selectSelectedCarId(state);
    return state.searchReducer.searchData.cars.find((car) => car.id === id);
};

export const selectSelectedCarId = (state) =>
    state.searchReducer.search.selectedCarId;

export const selectHasSelectedCar = (state) =>
    !!state.searchReducer.search.selectedCarId;

export const selectSortingParameterPriority = (state) =>
    state.searchReducer.ui.sortingPriority;

export const selectMapPosition = (state) => state.searchReducer.ui.mapPosition;

export const selectShowCityBikes = (state) =>
    state.searchReducer.ui.showCityBikes;
export const selectCityBikes = (state) => state.searchReducer.ui.cityBikes;

export const selectReservingCar = (state) =>
    state.searchReducer.searchData.reservingCar;

export const selectReservingCarResult = (state) =>
    state.searchReducer.searchData.reservingCarResult;

// Availability state
export const selectInitializingAvailability = (state) =>
    state.searchReducer.availability.initializingAvailability;

export const selectAvailabilityStartTime = (state) =>
    state.searchReducer.availability.startTime;
export const selectAvailabilityEndTime = (state) =>
    state.searchReducer.availability.endTime;

export const selectIsAvailable = (state) =>
    state.searchReducer.availability.isAvailable;

export const selectAvailablePeriods = (state) =>
    state.searchReducer.availability.availablePeriods;

export const selectAvailabilityReservedDays = (state) =>
    state.searchReducer.availability.reservedDays;

export const selectFetchingAvailabilityReservations = (state) =>
    state.searchReducer.availability.fetchingReservations;

export const selectFetchingAvailabilityInfo = (state) =>
    state.searchReducer.availability.fetchingAvailabilityInfo;

export const selectSuperDamageWaiver = (state) =>
    state.searchReducer.search.superDamageWaiver;

export const selectKilometerEstimate = (state) =>
    state.searchReducer.search.kilometerEstimate;
