import { handleActions } from 'redux-actions';
import actions from './actions';
import profileActions from '../pages/ProfilePage/duck/actions';
import { HEADING_CHOOSE_CAR, PAGE_CHOOSE_CAR } from './constants';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { apiCallError, apiCallSuccess } from '../../utilities/constants';
import { theme_bdr } from '../../utilities/themes';

const defaultDataState = {
    shouldAuthenticate: false,
    initializingApp: false,
    initializingAppError: false,
    initApp__positionCompleted: false,
    initApp__filtersCompleted: false,
    initApp__locationsCompleted: false,
    authentication: undefined,
    admin: undefined,
    userName: undefined,
    performAfterLogin: undefined,
    loadingText: '',
    whoamiResponse: undefined,
    selectedMembership: undefined,
};

const dataReducer = handleActions(
    {
        [actions.initApp]: (state) => ({
            ...state,
            initializingApp: false,
            initializingAppError: false,
            initApp__positionCompleted: false,
            initApp__filtersCompleted: false,
            initApp__locationsCompleted: false,
        }),

        [actions.initApp__positionCompleted]: (state) => ({
            ...state,
            initApp__positionCompleted: true,
        }),
        [actions.initApp__filtersCompleted]: (state) => ({
            ...state,
            initApp__filtersCompleted: true,
        }),
        [actions.initApp__locationsCompleted]: (state) => ({
            ...state,
            initApp__locationsCompleted: true,
        }),
        [actions.initAppError]: (state) => ({
            ...state,
            initializingAppError: true,
        }),
        [actions.setShouldAuthenticate]: (state, action) => ({
            ...state,
            shouldAuthenticate: action.payload,
        }),
        [actions.authenticationPending]: (state) => ({
            ...state,
            authenticationPending: true,
        }),
        [actions.authenticationCompleted]: (state, action) => ({
            ...state,
            authenticationPending: false,
            shouldAuthenticate: false,
            authentication: action.payload,
        }),
        [actions.authenticationError]: (state) => ({
            ...state,
            authenticationPending: false,
        }),

        [actions.vippsJoinPending]: (state) => ({
            ...state,
            vippsJoinPending: true,
            vippsJoinResult: undefined,
        }),
        [actions.vippsJoinCompleted]: (state, action) => ({
            ...state,
            vippsJoinPending: false,
            vippsJoinResult: apiCallSuccess,
            authentication: action.payload,
        }),
        [actions.vippsJoinError]: (state, action) => {
            return {
                ...state,
                vippsJoinPending: false,
                vippsJoinResult: apiCallError,
                vippsJoinErrors: action.payload,
            };
        },
        [actions.resetVippsJoinError]: (state) => {
            return {
                ...state,
                vippsJoinPending: false,
                vippsJoinResult: undefined,
                vippsJoinErrors: undefined,
            };
        },

        [actions.linkVippsUserPending]: (state) => ({
            ...state,
            linkVippsUserPending: true,
            linkVippsUserResult: undefined,
            linkVippsUserErrors: undefined,
        }),
        [actions.linkVippsUserCompleted]: (state) => ({
            ...state,
            linkVippsUserPending: false,
            linkVippsUserResult: apiCallSuccess,
        }),
        [actions.linkVippsUserError]: (state, action) => {
            return {
                ...state,
                linkVippsUserPending: false,
                linkVippsUserResult: apiCallError,
                linkVippsUserErrors: action.payload,
            };
        },

        [actions.clearAuthentication]: (state) => ({
            ...state,
            authentication: null,
        }),

        [actions.joinPending]: (state) => ({
            ...state,
            joinPending: true,
            joinResult: undefined,
            joinErrors: undefined,
        }),
        [actions.joinCompleted]: (state, action) => ({
            ...state,
            joinPending: false,
            joinResult: apiCallSuccess,
            joinedMembership: action.payload,
        }),
        [actions.joinError]: (state, action) => ({
            ...state,
            joinPending: false,
            joinResult: apiCallError,
            joinErrors: action.payload,
        }),
        [actions.resetJoinResult]: (state) => ({
            ...state,
            joinResult: undefined,
            joinErrors: undefined,
        }),

        [actions.gcreAuthPending]: (state) => ({
            ...state,
            gcreAuthPending: true,
            gcreAuthResult: undefined,
            gcreAuthResponse: undefined,
        }),
        [actions.gcreAuthCompleted]: (state, action) => ({
            ...state,
            gcreAuthPending: false,
            gcreAuthResult: apiCallSuccess,
            gcreAuthResponse: action.payload,
        }),
        [actions.gcreAuthError]: (state, action) => ({
            ...state,
            gcreAuthPending: false,
            gcreAuthResult: apiCallError,
            gcreAuthResponse: action.payload,
        }),

        [actions.sendPasswordResetEmailPending]: (state) => ({
            ...state,
            sendPasswordResetEmailPending: true,
            sendPasswordResetEmailResult: undefined,
        }),
        [actions.sendPasswordResetEmailCompleted]: (state) => ({
            ...state,
            sendPasswordResetEmailPending: false,
            sendPasswordResetEmailResult: apiCallSuccess,
        }),
        [actions.sendPasswordResetEmailError]: (state) => ({
            ...state,
            sendPasswordResetEmailPending: false,
            sendPasswordResetEmailResult: apiCallError,
        }),
        [actions.resetSendPasswordResetEmailResult]: (state) => ({
            ...state,
            sendPasswordResetEmailPending: false,
            sendPasswordResetEmailResult: undefined,
        }),

        [actions.resetPasswordPending]: (state) => ({
            ...state,
            resetPasswordPending: true,
            resetPasswordResult: undefined,
        }),
        [actions.resetPasswordCompleted]: (state) => ({
            ...state,
            resetPasswordPending: false,
            resetPasswordResult: apiCallSuccess,
        }),
        [actions.resetPasswordError]: (state) => ({
            ...state,
            resetPasswordPending: false,
            resetPasswordResult: apiCallError,
        }),
        [actions.resetResetPasswordResult]: (state) => ({
            ...state,
            resetPasswordPending: false,
            resetPasswordResult: undefined,
        }),

        [actions.setAdmin]: (state, action) => ({
            ...state,
            admin: action.payload,
        }),
        [actions.setUserName]: (state, action) => ({
            ...state,
            userName: action.payload,
        }),
        [actions.setPerformAfterLogin]: (state, action) => ({
            ...state,
            performAfterLogin: action.payload,
        }),
        [actions.clearPerformAfterLogin]: (state) => ({
            ...state,
            performAfterLogin: null,
        }),
        [actions.whoamiResponse]: (state, action) => ({
            ...state,
            whoamiResponse: action.payload,
        }),
        [profileActions.fetchMembershipMembersCompleted]: (state, action) => ({
            ...state,
            whoamiResponse: {
                ...state.whoamiResponse,
                memberships: action.payload.memberships,
            },
        }),

        [actions.fetchOrganizationsPending]: (state) => ({
            ...state,
            fetchingOrganizations: true,
            fetchOrganizationsResult: undefined,
        }),
        [actions.fetchOrganizationsCompleted]: (state, action) => ({
            ...state,
            fetchingOrganizations: false,
            fetchOrganizationsResult: apiCallSuccess,
            organizations: action.payload,
        }),
        [actions.fetchOrganizationsError]: (state) => ({
            ...state,
            fetchingOrganizations: false,
            fetchOrganizationsResult: apiCallError,
        }),

        [actions.setSelectedMembership]: (state, action) => ({
            ...state,
            selectedMembership: action.payload,
        }),
        [actions.setSelectedOrganization]: (state, action) => ({
            ...state,
            selectedOrganization: action.payload,
        }),
        [actions.clearDataState]: (state) => ({
            ...defaultDataState,
            organizations: state.organizations,
        }),
        CLEAR_STATE: (state) => {
            return {
                ...state,
                admin: undefined,
                userName: undefined,
                performAfterLogin: undefined,
                authentication: null,
                whoamiResponse: undefined,
                selectedMembership: undefined,
                selectedOrganization: undefined,
            };
        },
    },
    defaultDataState
);

const defaultPaginationState = {
    fetchingNewPage: false,
    lastPage: undefined,
    pageNumber: undefined,
};

const paginationReducer = handleActions(
    {
        [actions.fetchingNewPagePending]: (state) => ({
            ...state,
            fetchingNewPage: true,
        }),
        [actions.fetchingNewPageCompleted]: (state, action) => ({
            ...state,
            pageNumber: action.payload.page,
            lastPage: action.payload.lastPage,
            fetchingNewPage: false,
        }),
        [actions.fetchingNewPageError]: (state) => ({
            ...state,
            fetchingNewPage: false,
        }),
        [actions.resetPagination]: (state) => ({
            ...state,
            fetchingNewPage: false,
            pageNumber: undefined,
            lastPage: false,
        }),
        CLEAR_STATE: () => defaultPaginationState,
    },
    defaultPaginationState
);

const defaultSwapCarState = {
    swappingCar: false,
    swapCarResult: undefined,
};

const swapCarReducer = handleActions(
    {
        [actions.setSwapCarReservation]: (state, action) => ({
            ...state,
            swapCarReservation: action.payload,
        }),

        [actions.swapCarPending]: (state) => ({
            ...state,
            swappingCar: true,
            swapCarResult: undefined,
            swapCarErrorMessage: undefined,
        }),
        [actions.swapCarCompleted]: (state) => ({
            ...state,
            swappingCar: false,
            swapCarResult: apiCallSuccess,
            swapCarErrorMessage: undefined,
        }),
        [actions.swapCarError]: (state, action) => ({
            ...state,
            swappingCar: false,
            swapCarResult: apiCallError,
            swapCarErrorMessage: action.payload,
        }),
        [actions.resetSwapCarResult]: (state) => ({
            ...state,
            swappingCar: false,
            swapCarResult: undefined,
            swapCarErrorMessage: undefined,
        }),

        [actions.setSwapCarPosition]: (state, action) => ({
            ...state,
            swapCarPosition: action.payload,
        }),

        [actions.setSwapCarTimes]: (state, action) => ({
            ...state,
            swapCarTimes: action.payload,
        }),

        [actions.setSwapCarFilters]: (state, action) => ({
            ...state,
            swapCarFilters: action.payload,
        }),

        [actions.setSwapCarFiltersAreActive]: (state, action) => ({
            ...state,
            swapCarFiltersAreActive: action.payload,
        }),
        CLEAR_STATE: () => defaultSwapCarState,
    },
    defaultSwapCarState
);

const defaultUiState = {
    theme: theme_bdr,
    showHamburgerMenu: false,
    heading: HEADING_CHOOSE_CAR,
    page: PAGE_CHOOSE_CAR,
};

const uiReducer = handleActions(
    {
        [actions.setTheme]: (state, action) => ({
            ...state,
            theme: action.payload,
        }),
        [actions.toggleShowHamburgerMenu]: (state) => ({
            ...state,
            showHamburgerMenu: !state.showHamburgerMenu,
        }),
        [actions.showHamburgerMenu]: (state, action) => ({
            ...state,
            showHamburgerMenu: action.payload,
        }),
        [actions.setPage]: (state, action) => ({
            ...state,
            page: action.payload.page,
            heading: action.payload.heading,
        }),
        [actions.setShowIncorrectLoginFeedback]: (state, action) => ({
            ...state,
            showIncorrectLoginFeedback: action.payload,
        }),
        CLEAR_STATE: () => defaultUiState,
    },
    defaultUiState
);

const dataPersistConfig = {
    key: 'data',
    storage: storage,
    whitelist: [
        'authentication',
        'userName',
        'admin',
        'selectedMembership',
        'selectedOrganization',
        'organizations',
        'whoamiResponse',
    ],
};

const reducer = combineReducers({
    data: persistReducer(dataPersistConfig, dataReducer),
    swapCar: swapCarReducer,
    ui: uiReducer,
    pagination: paginationReducer,
});

export default reducer;
